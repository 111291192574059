import { useState, useRef, useCallback, useEffect } from 'react';
import { Title, Table, Badge, Paper } from '@mantine/core';
import { MindEvent } from '../types/MindEvent';

interface MindEventsProps {
  mindEvents: MindEvent[] | null;
}

export default function MindEvents({ mindEvents }: MindEventsProps) {
  const [displayedMindEvents, setDisplayedMindEvents] = useState<MindEvent[]>([]);
  const [mindEventsPage, setMindEventsPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const MIND_EVENTS_PER_PAGE = 20;

  const loadMoreMindEvents = useCallback(() => {
    if (!mindEvents) return;

    const nextPage = mindEventsPage + 1;
    const startIndex = (nextPage - 1) * MIND_EVENTS_PER_PAGE;
    const endIndex = nextPage * MIND_EVENTS_PER_PAGE;
    const newEvents = mindEvents.slice(startIndex, endIndex);

    setDisplayedMindEvents((prevEvents) => [...prevEvents, ...newEvents]);
    setMindEventsPage(nextPage);
    setHasMore(endIndex < mindEvents.length);
  }, [mindEvents, mindEventsPage]);

  useEffect(() => {
    setDisplayedMindEvents([]);
    setMindEventsPage(0);
    setHasMore(true);
  }, [mindEvents]);

  useEffect(() => {
    if (mindEventsPage === 0 && mindEvents) {
      loadMoreMindEvents();
    }
  }, [mindEvents, mindEventsPage, loadMoreMindEvents]);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastMindEventElementRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreMindEvents();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loadMoreMindEvents],
  );

  if (!mindEvents || mindEvents.length === 0) {
    return null;
  }

  return (
    <>
      <Title order={3} mt="xl" mb="md" style={{ display: 'flex', alignItems: 'center' }}>
        Mind Events <Badge ml="xs">{mindEvents.length}</Badge>
      </Title>
      <Paper
        shadow="xs"
        pt="xs"
        withBorder
        style={{ maxHeight: '600px', overflowY: 'auto' }}
      >
        <Table striped highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Timestamp</Table.Th>
              <Table.Th>Word</Table.Th>
              <Table.Th>Event Type</Table.Th>
              <Table.Th>Language</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {displayedMindEvents.map((event, index) => (
              <Table.Tr
                key={`${event.event_type}-${event.timestamp}-${event.word}`}
                ref={
                  index === displayedMindEvents.length - 1
                    ? lastMindEventElementRef
                    : null
                }
              >
                <Table.Td>{new Date(event.timestamp).toLocaleString()}</Table.Td>
                <Table.Td>{event.word}</Table.Td>
                <Table.Td>{event.event_type}</Table.Td>
                <Table.Td>{event.language}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Paper>
    </>
  );
}
