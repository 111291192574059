import {
  Button,
  TextInput,
  Container,
  Title,
  Paper,
  PasswordInput,
  Alert,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { getToken } from '../services/api';
import { useNavigate } from 'react-router-dom';
import classes from './LoginPage.module.css';
import { useState, useCallback } from 'react';

export default function LoginPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
  });

  const handleLogin = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      setError('');

      try {
        await getToken(form.values.email, form.values.password);
        navigate('/users');
      } catch (err) {
        console.error(err);
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    },
    [form.values, navigate],
  );

  return (
    <Container
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Title ta="center" className={classes.title}>
        Glite Admin
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} w={500} radius="md">
        <form onSubmit={handleLogin}>
          <TextInput
            label="Email"
            placeholder="you@glite.ai"
            value={form.values.email}
            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
            required
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            mt="md"
            value={form.values.password}
            onChange={(event) =>
              form.setFieldValue('password', event.currentTarget.value)
            }
            required
          />
          <Button fullWidth mt="xl" loading={loading} type="submit">
            Sign in
          </Button>
        </form>
      </Paper>

      {error && (
        <Alert
          variant="light"
          color="red"
          withCloseButton
          mt={30}
          w={500}
          onClose={() => setError('')}
        >
          {error}
        </Alert>
      )}
    </Container>
  );
}
