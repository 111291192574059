import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { logOut } from '../services/api.ts';

export default function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    logOut();
    navigate('/');
  }, [navigate]);

  return null;
}
