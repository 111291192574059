import {
  Box,
  LoadingOverlay,
  Table,
  Skeleton,
  TextInput,
  Text,
  rem,
  UnstyledButton,
  Group,
  Center,
  Pagination,
  Flex,
  Tooltip,
} from '@mantine/core';
import { Layout } from '../components/Layout.tsx';
import React, { useEffect, useState } from 'react';
import { getUserList } from '../services/api.ts';
import { User } from '../types/User.tsx';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import classes from './UserList.module.css';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent } from 'react';

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  width: string;
  onSort(): void;
}

function Th({ children, reversed, sorted, width, onSort }: ThProps) {
  const Icon = sorted ? (reversed ? ChevronUpIcon : ChevronDownIcon) : ChevronUpDownIcon;
  return (
    <Table.Th className={classes.th} style={{ width: width }}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

function filterData(data: User[], search: string) {
  const query = search.toLowerCase().trim();

  return data.filter((item) =>
    Object.keys(item).some((key) => {
      const value = item[key as keyof User];
      return typeof value === 'string' && value.toLowerCase().includes(query);
    }),
  );
}

function sortData(
  data: User[],
  payload: { sortBy: keyof User | null; reversed: boolean; search: string },
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (aValue === null || bValue === null) {
        return aValue === null ? 1 : -1; // Move null values to the end of the list
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return payload.reversed
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue);
      }

      return 0; // If the values are not strings, treat them as equal for sorting purposes
    }),
    payload.search,
  );
}

export default function UserList() {
  const [loading, setLoading] = useState(true);
  const [page] = useState(0);
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState([] as User[]);
  const [sortBy, setSortBy] = useState<keyof User | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 20;
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const defaultSortBy = 'last_active_at';

  const setSorting = (field: keyof User) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(sortedData, { sortBy: field, reversed, search }));
  };

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await getUserList(page);
        setSortBy(defaultSortBy);
        setSortedData(
          sortData(response, {
            sortBy: defaultSortBy,
            reversed: true,
            search: '',
          }),
        );
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserList().catch(console.error);
  }, [page]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(sortedData, { sortBy, reversed: reverseSortDirection, search: value }),
    );
  };

  let rows;
  if (!loading) {
    rows = sortedData.map((element) => {
      const lastActive = element.last_active_at
        ? new Date(element.last_active_at).toLocaleString()
        : '';
      const created = element.created_at
        ? new Date(element.created_at).toLocaleString()
        : '';

      return (
        <Table.Tr
          key={element.user_id}
          className={classes.tr}
          onClick={() => navigate(`/users/${element.user_id}`)}
        >
          <Table.Td>
            <Text style={{ display: 'inline-flex', alignItems: 'center', gap: '5px' }}>
              {element.email}{' '}
              {element.email &&
                (element.email_verified ? (
                  <Tooltip label="Email verified">
                    <CheckIcon
                      style={{ width: rem(20), height: rem(20), color: 'green' }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip label="Email not verified">
                    <XMarkIcon
                      style={{ width: rem(20), height: rem(20), color: 'red' }}
                    />
                  </Tooltip>
                ))}
            </Text>
          </Table.Td>
          <Table.Td>{element.user_id}</Table.Td>
          <Table.Td>{lastActive}</Table.Td>
          <Table.Td>{created}</Table.Td>
        </Table.Tr>
      );
    });
  } else {
    rows = Array.from({ length: 20 }).map((_, index) => (
      <Table.Tr key={index} className={classes.tr}>
        <Table.Td>
          <Skeleton height={16} width="100%" />
        </Table.Td>
        <Table.Td>
          <Skeleton height={16} width="100%" />
        </Table.Td>
        <Table.Td>
          <Skeleton height={16} width="100%" />
        </Table.Td>
        <Table.Td>
          <Skeleton height={16} width="100%" />
        </Table.Td>
      </Table.Tr>
    ));
  }

  rows = rows.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Layout title="User List">
      <Box pos="relative">
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <TextInput
          placeholder="Search by any field"
          mb="md"
          leftSection={
            <MagnifyingGlassIcon style={{ width: rem(16), height: rem(16) }} />
          }
          value={search}
          onChange={handleSearchChange}
        />
        <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
          <Table.Thead>
            <Table.Tr>
              <Th
                sorted={sortBy === 'email'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('email')}
                width="30%"
              >
                Email
              </Th>
              <Th
                sorted={sortBy === 'user_id'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('user_id')}
                width="30%"
              >
                Glite ID
              </Th>
              <Th
                sorted={sortBy === 'last_active_at'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('last_active_at')}
                width="20%"
              >
                Active
              </Th>
              <Th
                sorted={sortBy === 'created_at'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('created_at')}
                width="20%"
              >
                Created
              </Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <Table.Tr>
                <Table.Td colSpan={4}>
                  <Text fw={500} ta="center">
                    Nothing found
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
        <Flex mt="xl" direction="column" align="center" justify="center" gap="sm">
          <Pagination total={totalPages} value={currentPage} onChange={setCurrentPage} />
          <Text c="dimmed">{sortedData.length} users</Text>
        </Flex>
      </Box>
    </Layout>
  );
}
