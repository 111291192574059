import {
  Modal,
  Text,
  Stack,
  Group,
  Paper,
  Box,
  Button,
  Table,
  Badge,
} from '@mantine/core';
import { Line } from 'react-chartjs-2';
import { DebugWordKnowledge } from '../types/Skill';
import { MindEvent } from '../types/MindEvent';
import { ChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface SkillModalProps {
  skill: DebugWordKnowledge;
  mindEvents: MindEvent[];
  onClose: () => void;
}

export default function SkillModal({ skill, mindEvents, onClose }: SkillModalProps) {
  const formatDateForChart = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  };

  const prepareChartData = (skill: DebugWordKnowledge) => {
    const allDates = Object.keys(skill.word_knowledge.retrievability_history || {}).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );

    const data = allDates.map((date) => {
      const formattedDate = formatDateForChart(date);
      const skillR = skill.word_knowledge.retrievability_history?.[date] || 0;
      const percentR = skillR * 100;
      return {
        x: formattedDate,
        y: percentR,
      };
    });

    const lookupData = skill.word_knowledge.lookup_datetimes.map((d) => ({
      x: formatDateForChart(d.toString()),
      y: 1,
    }));

    const importData = skill.word_knowledge.import_datetimes.map((d) => ({
      x: formatDateForChart(d.toString()),
      y: 1,
    }));

    const studyData = skill.word_knowledge.studied_datetimes.map((d) => ({
      x: formatDateForChart(d.toString()),
      y: 1,
    }));

    return {
      labels: data.map((item) => item.x),
      datasets: [
        {
          label: 'Retrievability (%)',
          data: data,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          yAxisID: 'y',
        },
        {
          label: 'Lookup',
          data: lookupData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'y1',
          pointStyle: 'circle',
          pointRadius: 6,
          showLine: false,
        },
        {
          label: 'Import',
          data: importData,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          yAxisID: 'y1',
          pointStyle: 'triangle',
          pointRadius: 6,
          showLine: false,
        },
        {
          label: 'Study',
          data: studyData,
          borderColor: 'rgb(255, 205, 86)',
          backgroundColor: 'rgba(255, 205, 86, 0.5)',
          yAxisID: 'y1',
          pointStyle: 'star',
          pointRadius: 6,
          showLine: false,
        },
      ],
    };
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Retrievability',
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          callback: (value) => Number(value).toFixed(1),
        },
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Events',
        },
        suggestedMin: -0.1,
        suggestedMax: 1.1,
        ticks: {
          stepSize: 1,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  return (
    <Modal
      opened={true}
      onClose={onClose}
      title={
        <Group justify="space-between">
          <Text size="xl" fw={700}>
            {skill.word_knowledge.word}
          </Text>
          <Badge size="sm" color={skill.colour}>
            {skill.word_knowledge.language}
          </Badge>
        </Group>
      }
      size="xl"
    >
      <Stack gap="md">
        <Group grow align="stretch">
          <Paper
            withBorder
            p="md"
            radius="md"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Text size="sm" c="dimmed" mb="xs">
              Retrievability
            </Text>
            <Text size="xl" fw={700} c={skill.colour} style={{ flexGrow: 1 }}>
              {skill.last_retrievability ? `${skill.last_retrievability}%` : 'N/A'}
            </Text>
            <Text size="xs" c="dimmed" mt="xs">
              {skill.last_retrievability_datetime &&
                new Date(skill.last_retrievability_datetime).toLocaleString()}
            </Text>
          </Paper>
          <Paper
            withBorder
            p="md"
            radius="md"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Text size="sm" c="dimmed" mb="xs">
              Study Count
            </Text>
            <Text size="xl" fw={700} style={{ flexGrow: 1 }}>
              {skill.word_knowledge.studied_datetimes.length}
            </Text>
          </Paper>
          <Paper
            withBorder
            p="md"
            radius="md"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Text size="sm" c="dimmed" mb="xs">
              Lookup Count
            </Text>
            <Text size="xl" fw={700} style={{ flexGrow: 1 }}>
              {skill.word_knowledge.lookup_datetimes.length}
            </Text>
          </Paper>
          <Paper
            withBorder
            p="md"
            radius="md"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Text size="sm" c="dimmed" mb="xs">
              Import Count
            </Text>
            <Text size="xl" fw={700} style={{ flexGrow: 1 }}>
              {skill.word_knowledge.import_datetimes.length}
            </Text>
          </Paper>
        </Group>

        <Paper withBorder p="md" radius="md">
          <Text fw={600} size="lg" mb="md">
            Retrievability History
          </Text>
          <Box style={{ height: '350px' }}>
            {skill.word_knowledge.retrievability_history &&
            Object.keys(skill.word_knowledge.retrievability_history).length > 0 ? (
              <Line options={chartOptions} data={prepareChartData(skill)} />
            ) : (
              <Text>No retrievability history available</Text>
            )}
          </Box>
        </Paper>

        <Paper withBorder p="md" radius="md">
          <Text fw={600} size="lg" mb="md">
            Mind Events
          </Text>
          <Table striped highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Timestamp</Table.Th>
                <Table.Th>Word</Table.Th>
                <Table.Th>Event Type</Table.Th>
                <Table.Th>Language</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {mindEvents.map((event) => {
                if (!event) {
                  return null;
                }

                if (
                  event.word?.toLowerCase() !== skill.word_knowledge.word.toLowerCase()
                ) {
                  return null;
                }

                return (
                  <Table.Tr key={`${event.timestamp}-${event.word}`}>
                    <Table.Td>{new Date(event.timestamp).toLocaleString()}</Table.Td>
                    <Table.Td>{event.word}</Table.Td>
                    <Table.Td>{event.event_type}</Table.Td>
                    <Table.Td>{event.language}</Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        </Paper>

        <Group p="apart">
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
