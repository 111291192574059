import { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Alert,
  LoadingOverlay,
  Title,
  Divider,
  Flex,
  Image,
  Text,
  Modal,
  Tooltip,
  Badge,
} from '@mantine/core';

import { FeedTypeEnum, User } from '../types/User.tsx';
import { getFeedDetail, getFeedDetailResponse, getUserDetail } from '../services/api';
import {
  FlashCardSession,
  AudioDJSession,
  Card,
  AudioTrack,
  CompletedTask,
} from '../types/Feed.tsx';

import { Table } from '@mantine/core';
import ReactAudioPlayer from 'react-audio-player';
import { useParams } from 'react-router-dom';
import { Layout } from '../components/Layout.tsx';
import { PlayIcon } from '@heroicons/react/24/solid';
import { useDisclosure } from '@mantine/hooks';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

function FlashCardSessionItem({
  session,
  completedTasks,
  onOpenModal,
}: {
  session: FlashCardSession;
  completedTasks: CompletedTask[];
  onOpenModal: (index: number) => void;
}) {
  let completedTasksCount = 0;
  for (const card of session.content.cards) {
    for (const task of completedTasks) {
      if (task.orchestrator_id === card.orchestrator_id) {
        completedTasksCount++;
        break;
      }
    }
  }

  const isCompleted = completedTasksCount === session.content.cards.length;
  if (completedTasksCount > session.content.cards.length) {
    console.error(
      'Completed tasks count is greater than the number of cards in the session',
    );
  }

  return (
    <Box mb="md" p="md" style={{ border: '1px solid #eee', borderRadius: '4px' }}>
      <Title order={4} style={{ display: 'flex', alignItems: 'center' }}>
        Flashcard Session{' '}
        {isCompleted ? (
          <Badge color="green" ml="xs">
            Completed
          </Badge>
        ) : (
          <Badge color="red" ml="xs">
            Incomplete
          </Badge>
        )}
      </Title>
      <Table mt="md" withRowBorders={false}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>Session ID</Table.Td>
            <Table.Td>{session.id}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Cards</Table.Td>
            <Table.Td>{session.content_items_count}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Language</Table.Td>
            <Table.Td>{session.content.language}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Created</Table.Td>
            <Table.Td>{new Date(session.created_at).toLocaleString()}</Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>

      <Box mt="md">
        <Flex wrap="wrap" gap="xs" justify="space-between">
          {session.content.cards.map((card: Card, index: number) => (
            <FlashcardItem
              card={card}
              key={card.card_id}
              completedTasks={completedTasks}
              onOpenModal={() => onOpenModal(index)}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
}

function FlashcardItem({
  card,
  completedTasks,
  onOpenModal,
}: {
  card: Card;
  completedTasks: CompletedTask[];
  onOpenModal: () => void;
}) {
  const capitalizedWord = card.word.charAt(0).toUpperCase() + card.word.slice(1);
  const completedTask = completedTasks.find(
    (task) => task.orchestrator_id === card.orchestrator_id,
  );
  const isCompleted = !!completedTask;

  return (
    <Box
      style={{
        width: 'calc(20% - 8px)',
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
      }}
      onClick={onOpenModal}
    >
      <Image
        src={card.card_content.back.image_uri}
        alt={card.word}
        fit="cover"
        style={{ objectPosition: 'center' }}
      />
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'linear-gradient(transparent, transparent, transparent 40%, rgba(0, 0, 0, 0.8))',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Text ta="left" c="white" fz="sm" fw="bold">
          {capitalizedWord}
        </Text>
        <Text ta="left" c="white" fz="xs" lineClamp={2}>
          {card.card_content.back.text}
        </Text>
      </Box>
      {isCompleted && (
        <Tooltip
          label={`Completed on ${new Date(completedTask.completed_at).toLocaleString()}`}
          position="top"
          withArrow
        >
          <Box
            style={{
              position: 'absolute',
              top: 5,
              right: 5,
              background: 'white',
              borderRadius: '50%',
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CheckCircleIcon width={20} height={20} style={{ color: 'green' }} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

function AudioDJSessionItem({
  session,
  completedTasks,
}: {
  session: AudioDJSession;
  completedTasks: CompletedTask[];
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

  const formatDuration = (milliseconds: number) => {
    const totalSeconds = Math.round(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString()}m ${seconds.toString()}s`;
  };

  const stopAllOtherPlayers = () => {
    const allPlayers = document.querySelectorAll('audio');
    allPlayers.forEach((player) => {
      if (player.id !== `audio-player-${session.id}`) {
        player.pause();
      }
    });
  };

  const handlePlay = () => {
    stopAllOtherPlayers();
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleEnded = () => {
    if (currentTrackIndex < session.content.tracks.length - 1) {
      setCurrentTrackIndex((prevIndex) => prevIndex + 1);
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
      setCurrentTrackIndex(0);
    }
  };

  const playTrack = (index: number) => {
    if (index < session.content.tracks.length) {
      stopAllOtherPlayers();
      setCurrentTrackIndex(index);
      setIsPlaying(true);
    }
  };

  return (
    <Box mb="md" p="md" style={{ border: '1px solid #eee', borderRadius: '4px' }}>
      <Title order={4}>
        AudioDJ Session{' '}
        {completedTasks &&
        completedTasks.some((task) => task.orchestrator_id === session.id)
          ? ' (Completed)'
          : ''}
      </Title>
      <Table mt="md" withRowBorders={false}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>Session ID</Table.Td>
            <Table.Td>{session.id}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Tracks</Table.Td>
            <Table.Td>{session.content_items_count}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Language</Table.Td>
            <Table.Td>{session.content.language}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Total Length</Table.Td>
            <Table.Td>{formatDuration(session.content.length)}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Created</Table.Td>
            <Table.Td>{new Date(session.created_at).toLocaleString()}</Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
      <Table mt="lg">
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th>Index</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Title</Table.Th>
            <Table.Th>Subtitle</Table.Th>
            <Table.Th>Words</Table.Th>
            <Table.Th>Length</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {session.content.tracks.map((track: AudioTrack, index: number) => (
            <Table.Tr
              key={track.track_id}
              onClick={() => playTrack(index)}
              style={{
                cursor: 'pointer',
              }}
              pos="relative"
            >
              <Table.Td>
                <PlayIcon
                  width={16}
                  height={16}
                  style={{
                    color:
                      currentTrackIndex === index
                        ? isPlaying
                          ? 'green'
                          : 'lightgrey'
                        : 'transparent',
                    verticalAlign: 'middle',
                  }}
                />
              </Table.Td>
              <Table.Td>{index + 1}</Table.Td>
              <Table.Td>{track.track_type}</Table.Td>
              <Table.Td>{track.title}</Table.Td>
              <Table.Td>{track.subtitle}</Table.Td>
              <Table.Td>{track.words.join(', ')}</Table.Td>
              <Table.Td>{formatDuration(track.length)}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>

      <ReactAudioPlayer
        id={`audio-player-${session.id}`}
        src={session.content.tracks[currentTrackIndex].audio_file_url}
        controls
        style={{ width: '100%', marginTop: '1rem' }}
        autoPlay={isPlaying}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
      />
    </Box>
  );
}

export default function FeedDetail() {
  const { id } = useParams<{ id: string }>();
  const [feedData, setFeedData] = useState<getFeedDetailResponse | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
  const [allFlashcards, setAllFlashcards] = useState<Card[]>([]);
  const [completedTasks, setCompletedTasks] = useState<CompletedTask[]>([]);

  useEffect(() => {
    const fetchFeedDetail = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getFeedDetail(id!);
        if (response) {
          setFeedData(response);
        } else {
          setError(`User with ID ${id} not found`);
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch feed details');
      } finally {
        setLoading(false);
      }
    };

    fetchFeedDetail().catch(console.error);
  }, [id]);

  useEffect(() => {
    if (feedData) {
      const flashcards = feedData.items
        .filter((item): item is FlashCardSession => item.content_type === 'flash_cards')
        .flatMap((session) => session.content.cards);
      setAllFlashcards(flashcards);

      const fetchUserDetails = async () => {
        const response = await getUserDetail({
          id: feedData.feed.user_id,
          feeds: true,
          completed_tasks: true,
        });

        if (response && response.user) {
          setUser(response.user);
          if (response.completed_tasks) {
            setCompletedTasks(response.completed_tasks);
          }
        } else {
          setError('Failed to fetch user details');
        }
      };

      fetchUserDetails().catch(console.error);
    }
  }, [feedData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (opened) {
        if (event.key === 'ArrowLeft') {
          setCurrentFlashcardIndex((prev) =>
            prev > 0 ? prev - 1 : allFlashcards.length - 1,
          );
        } else if (event.key === 'ArrowRight') {
          setCurrentFlashcardIndex((prev) =>
            prev < allFlashcards.length - 1 ? prev + 1 : 0,
          );
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [id, opened, allFlashcards]);

  const handleOpenModal = useCallback(
    (index: number) => {
      setCurrentFlashcardIndex(index);
      open();
    },
    [open],
  );

  const currentCard = allFlashcards[currentFlashcardIndex];

  return (
    <Layout title="Feed Details">
      <Box pos="relative">
        {error && (
          <Alert color="red">
            <strong>Error:</strong> {error}
          </Alert>
        )}
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />

        {feedData && (
          <>
            <Title order={3} mb="lg">
              {feedData.feed.feed_type === FeedTypeEnum.VISUAL ? 'Visual' : 'Audio'} Feed
            </Title>

            <Table>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>User ID</Table.Td>
                  <Table.Td>
                    <a href={`/users/${feedData.feed.user_id}`}>
                      {feedData.feed.user_id}
                    </a>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Name</Table.Td>
                  <Table.Td>{user?.name}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Email</Table.Td>
                  <Table.Td>{user?.email}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Language</Table.Td>
                  <Table.Td>{feedData.feed.language}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Client ID</Table.Td>
                  <Table.Td>{feedData.feed.client.client_id}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Client Platform</Table.Td>
                  <Table.Td>{feedData.feed.client.client_platform}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Client Version</Table.Td>
                  <Table.Td>{feedData.feed.client.client_version}</Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>

            <Divider my="lg" />

            <Title order={3} mb="lg">
              Feed Items
            </Title>

            {feedData.items.map((item) => {
              if (item.content_type === 'flash_cards') {
                return (
                  <FlashCardSessionItem
                    session={item as FlashCardSession}
                    completedTasks={completedTasks}
                    key={item.id}
                    onOpenModal={handleOpenModal}
                  />
                );
              } else if (item.content_type === 'audio_session') {
                return (
                  <AudioDJSessionItem
                    session={item as AudioDJSession}
                    key={item.id}
                    completedTasks={completedTasks}
                  />
                );
              }
              return null;
            })}

            <Modal
              opened={opened}
              onClose={close}
              size="lg"
              withCloseButton={false}
              centered
              styles={{
                body: { padding: 0 },
                inner: { backdropFilter: 'blur(4px)' },
              }}
            >
              {currentCard && (
                <Box style={{ position: 'relative' }}>
                  <Image
                    src={currentCard.card_content.back.image_uri}
                    alt={currentCard.word}
                    width="100%"
                    height="auto"
                    fit="contain"
                  />
                  <Box
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background:
                        'linear-gradient(transparent, transparent, transparent 40%, rgba(0, 0, 0, 1))',
                      padding: '20px',
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Text size="xl" mb="xs" style={{ fontWeight: 'bold' }}>
                      {currentCard.word.charAt(0).toUpperCase() +
                        currentCard.word.slice(1)}
                    </Text>
                    {currentCard.card_content.back.text.split('\n').map((line, index) => (
                      <Text
                        key={index}
                        size="md"
                        mt={
                          index ===
                          currentCard.card_content.back.text.split('\n').length - 1
                            ? 'xs'
                            : 0
                        }
                      >
                        {line}
                      </Text>
                    ))}
                  </Box>
                  {completedTasks.some(
                    (task) => task.orchestrator_id === currentCard.orchestrator_id,
                  ) && (
                    <Tooltip
                      label={`Completed on ${new Date(completedTasks.find((task) => task.orchestrator_id === currentCard.orchestrator_id)!.completed_at).toLocaleString()}`}
                      position="bottom"
                      withArrow
                    >
                      <Box
                        style={{
                          position: 'absolute',
                          top: 10,
                          right: 10,
                          background: 'white',
                          borderRadius: '50%',
                          width: 32,
                          height: 32,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CheckCircleIcon
                          width={28}
                          height={28}
                          style={{ color: 'green' }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              )}
            </Modal>
          </>
        )}
      </Box>
    </Layout>
  );
}
