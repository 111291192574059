import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Alert, Title, Center, Loader } from '@mantine/core';
import { getUserDetail } from '../services/api';
import { Layout } from '../components/Layout.tsx';
import { Feed, User } from '../types/User';
import { DebugWordKnowledge } from '../types/Skill';
import { MindEvent } from '../types/MindEvent';
import UserInfo from '../components/UserInfo';
import UserFeeds from '../components/UserFeeds';
import UserSkills from '../components/UserSkills';
import MindEvents from '../components/MindEvents';

export default function UserDetail() {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [feeds, setFeeds] = useState<Feed[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [mindEvents, setMindEvents] = useState<MindEvent[]>([]);
  const [skills, setSkills] = useState<DebugWordKnowledge[]>([]);

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const response = await getUserDetail({
          id: id!,
          feeds: true,
          skills: true,
          mind_events: true,
        });

        if (response) {
          setUser(response.user);
          setFeeds(response.feeds ?? []);
          setSkills(response.skills ?? []);
          setMindEvents(response.mind_events ?? []);
        } else {
          setError(`User with ID ${id} not found`);
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch user details');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetail();
  }, [id]);

  return (
    <Layout title="User Details">
      <Box pos="relative">
        {loading ? (
          <Center style={{ height: '100vh' }}>
            <Loader size="xl" />
          </Center>
        ) : (
          <>
            {error && (
              <Alert color="red">
                <strong>Error:</strong> {error}
              </Alert>
            )}
            <LoadingOverlay
              visible={loading}
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
            />

            {user && (
              <>
                <Title order={3} mb="lg">
                  {user.name ? user.name : user.email}
                </Title>
                <UserInfo user={user} />
                <UserFeeds feeds={feeds} />
                <UserSkills skills={skills} mindEvents={mindEvents || []} />
                <MindEvents mindEvents={mindEvents || []} />
              </>
            )}
          </>
        )}
      </Box>
    </Layout>
  );
}
