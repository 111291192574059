import { SimpleGrid, Paper, Stack, Group, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  UserIcon,
  MapPinIcon,
  LanguageIcon,
  CalendarIcon,
} from '@heroicons/react/24/solid';
import { User } from '../types/User';

interface UserInfoProps {
  user: User;
}

export default function UserInfo({ user }: UserInfoProps) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <SimpleGrid cols={isMobile ? 1 : 2} spacing="md">
      <Paper shadow="xs" p="md" withBorder>
        <Stack>
          <Group>
            <UserIcon width={20} height={20} />
            <Text fw={500}>Personal Information</Text>
          </Group>
          <SimpleGrid cols={2} spacing="xs">
            <Text c="dimmed">User ID:</Text>
            <Text>{user.user_id}</Text>
            <Text c="dimmed">Name:</Text>
            <Text>{user.name}</Text>
            <Text c="dimmed">Email:</Text>
            <Text>{user.email}</Text>
            <Text c="dimmed">Gender:</Text>
            <Text>{user.gender}</Text>
            <Text c="dimmed">Year of Birth:</Text>
            <Text>{user.year_of_birth}</Text>
          </SimpleGrid>
        </Stack>
      </Paper>

      <Paper shadow="xs" p="md" withBorder>
        <Stack>
          <Group>
            <MapPinIcon width={20} height={20} />
            <Text fw={500}>Location</Text>
          </Group>
          <SimpleGrid cols={2} spacing="xs">
            <Text c="dimmed">Location:</Text>
            <Text>{user.location}</Text>
            <Text c="dimmed">Country of Residence:</Text>
            <Text>{user.country_of_residence}</Text>
          </SimpleGrid>
        </Stack>
      </Paper>

      <Paper shadow="xs" p="md" withBorder>
        <Stack>
          <Group>
            <LanguageIcon width={20} height={20} />
            <Text fw={500}>Language & Education</Text>
          </Group>
          <SimpleGrid cols={2} spacing="xs">
            <Text c="dimmed">Native Language:</Text>
            <Text>{user.native_language}</Text>
            <Text c="dimmed">Education Level:</Text>
            <Text>{user.education_level}</Text>
            <Text c="dimmed">Industry:</Text>
            <Text>{user.industry}</Text>
          </SimpleGrid>
        </Stack>
      </Paper>

      <Paper shadow="xs" p="md" withBorder>
        <Stack>
          <Group>
            <CalendarIcon width={20} height={20} />
            <Text fw={500}>Activity</Text>
          </Group>
          <SimpleGrid cols={2} spacing="xs">
            <Text c="dimmed">Last Active At:</Text>
            <Text>{new Date(user.last_active_at).toLocaleString()}</Text>
            <Text c="dimmed">Last Updated At:</Text>
            <Text>{new Date(user.last_updated_at).toLocaleString()}</Text>
            <Text c="dimmed">Created At:</Text>
            <Text>{new Date(user.created_at).toLocaleString()}</Text>
          </SimpleGrid>
        </Stack>
      </Paper>
    </SimpleGrid>
  );
}
