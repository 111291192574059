import { useState } from 'react';
import {
  Title,
  Group,
  Switch,
  Select,
  Paper,
  SimpleGrid,
  Text,
  Badge,
  Tooltip,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DebugWordKnowledge } from '../types/Skill';
import { MindEvent } from '../types/MindEvent';
import SkillModal from './SkillModal.tsx';

interface UserSkillsProps {
  skills: DebugWordKnowledge[] | null;
  mindEvents: MindEvent[];
}

export default function UserSkills({ skills, mindEvents }: UserSkillsProps) {
  const [hideKnownWords, setHideKnownWords] = useState(true);
  const [skillSortOption, setSkillSortOption] = useState<string>('default');
  const [activeSkill, setActiveSkill] = useState<DebugWordKnowledge | null>(null);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const sortSkills = (skills: DebugWordKnowledge[]) => {
    switch (skillSortOption) {
      case 'recentlyStudied':
        return [...skills].sort((a, b) => {
          const aDate =
            a.word_knowledge.studied_datetimes[
              a.word_knowledge.studied_datetimes.length - 1
            ] || 0;
          const bDate =
            b.word_knowledge.studied_datetimes[
              b.word_knowledge.studied_datetimes.length - 1
            ] || 0;
          return new Date(bDate).getTime() - new Date(aDate).getTime();
        });
      case 'recentlyLookedUp':
        return [...skills].sort((a, b) => {
          const aDate =
            a.word_knowledge.lookup_datetimes[
              a.word_knowledge.lookup_datetimes.length - 1
            ] || 0;
          const bDate =
            b.word_knowledge.lookup_datetimes[
              b.word_knowledge.lookup_datetimes.length - 1
            ] || 0;
          return new Date(bDate).getTime() - new Date(aDate).getTime();
        });
      case 'recentlyImported':
        return [...skills].sort((a, b) => {
          const aDate =
            a.word_knowledge.import_datetimes[
              a.word_knowledge.import_datetimes.length - 1
            ] || 0;
          const bDate =
            b.word_knowledge.import_datetimes[
              b.word_knowledge.import_datetimes.length - 1
            ] || 0;
          return new Date(bDate).getTime() - new Date(aDate).getTime();
        });
      case 'mostStudied':
        return [...skills].sort(
          (a, b) =>
            b.word_knowledge.studied_datetimes.length -
            a.word_knowledge.studied_datetimes.length,
        );
      case 'highestRetrievability':
        return [...skills].sort(
          (a, b) => (b.last_retrievability || 0) - (a.last_retrievability || 0),
        );
      case 'lowestRetrievability':
        return [...skills].sort(
          (a, b) => (a.last_retrievability || 0) - (b.last_retrievability || 0),
        );
      default:
        return skills;
    }
  };

  const filteredAndSortedSkills = sortSkills(
    skills?.filter(
      (skill) => !hideKnownWords || !skill.word_knowledge.marked_known_by_user,
    ) || [],
  );

  const handleSortOptionChange = (value: string | null) => {
    if (value) {
      setSkillSortOption(value);
    }
  };

  if (!skills || skills.length === 0) {
    return null;
  }

  return (
    <>
      <Title order={3} mt="xl" mb="md" style={{ display: 'flex', alignItems: 'center' }}>
        Skills <Badge ml="xs">{skills.length}</Badge>
      </Title>

      <Group mb="md" justify="space-between">
        <Switch
          label="Hide words marked as known"
          checked={hideKnownWords}
          onChange={(event) => setHideKnownWords(event.currentTarget.checked)}
        />
        <Select
          label="Sort by"
          labelProps={{ display: 'none' }}
          value={skillSortOption}
          onChange={handleSortOptionChange}
          data={[
            { value: 'default', label: 'Sort by' },
            { value: 'recentlyStudied', label: 'Most recently studied' },
            { value: 'recentlyLookedUp', label: 'Most recently looked up' },
            { value: 'recentlyImported', label: 'Most recently imported' },
            { value: 'mostStudied', label: 'Most studied' },
            { value: 'highestRetrievability', label: 'Highest retrievability' },
            { value: 'lowestRetrievability', label: 'Lowest retrievability' },
          ]}
        />
      </Group>

      <Paper
        shadow="xs"
        p="xs"
        withBorder
        style={{ maxHeight: '600px', overflowY: 'auto' }}
      >
        <SimpleGrid cols={isMobile ? 1 : 6} spacing="sm">
          {filteredAndSortedSkills.map((skill) => (
            <Paper
              key={`${skill.word_knowledge.language}-${skill.word_knowledge.word}`}
              shadow="xs"
              p="sm"
              withBorder
              onClick={() => {
                setActiveSkill(skill);
              }}
              style={{
                textDecoration: 'none',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                },
              }}
            >
              <Group justify="space-between" mb="xs">
                <Text size="sm" fw={500} lineClamp={1}>
                  {skill.word_knowledge.word}
                </Text>
                {skill.word_knowledge.marked_known_by_user &&
                skill.word_knowledge.marked_known_by_user_at ? (
                  <Tooltip
                    label={`User marked as known on ${new Date(
                      skill.word_knowledge.marked_known_by_user_at,
                    ).toLocaleString()}`}
                  >
                    <Badge color={skill.colour} size="sm">
                      Marked Known
                    </Badge>
                  </Tooltip>
                ) : (
                  <Tooltip
                    label={`${skill.last_retrievability || 0}% known as of ${new Date(
                      skill.last_retrievability_datetime,
                    ).toLocaleString()}`}
                  >
                    <Badge color={skill.colour} size="sm">
                      {skill.last_retrievability || 0}%
                    </Badge>
                  </Tooltip>
                )}
              </Group>
              <Text size="xs" c="dimmed">
                {skill.word_knowledge.studied_datetimes.length}{' '}
                {skill.word_knowledge.studied_datetimes.length === 1
                  ? 'study'
                  : 'studies'}
                , {skill.word_knowledge.lookup_datetimes.length}{' '}
                {skill.word_knowledge.lookup_datetimes.length === 1
                  ? 'lookup'
                  : 'lookups'}
                , {skill.word_knowledge.import_datetimes.length}{' '}
                {skill.word_knowledge.import_datetimes.length === 1
                  ? 'import'
                  : 'imports'}
              </Text>
            </Paper>
          ))}
        </SimpleGrid>
      </Paper>

      {activeSkill && (
        <SkillModal
          skill={activeSkill}
          mindEvents={mindEvents}
          onClose={() => setActiveSkill(null)}
        />
      )}
    </>
  );
}
