import axios, { AxiosError } from 'axios';
import { Feed, User } from '../types/User.tsx';
import { AudioDJSession, CompletedTask, FlashCardSession } from '../types/Feed.tsx';
import { MindEvent } from '../types/MindEvent.tsx';
import { DebugWordKnowledge } from '../types/Skill.tsx';

const API_URL = process.env.GLITE_API_URL || 'http://127.0.0.1:8000';
const L2_API_BASE_URL = `${API_URL}/l2/v1`;

interface TokenResponse {
  access: string;
  refresh: string;
}

const apiClient = axios.create({
  baseURL: L2_API_BASE_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getStoredToken();
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

let isRefreshing = false;
let failedRequestsQueue: Array<() => void> = [];

async function refreshAccessToken() {
  const refreshToken = getStoredRefreshToken();
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await axios.post<TokenResponse>(`${L2_API_BASE_URL}/token/refresh`, {
      refresh: refreshToken,
    });
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);
    return response.data.access;
  } catch {
    logOut();
    throw new Error('Session expired. Please log in again.');
  }
}

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (getStoredRefreshToken()) {
        if (isRefreshing) {
          return new Promise<void>((resolve) => {
            failedRequestsQueue.push(() => {
              originalRequest.headers.Authorization = `Bearer ${getStoredToken()}`;
              resolve(apiClient(originalRequest));
            });
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const newToken = await refreshAccessToken();
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          // Retry all failed requests
          failedRequestsQueue.forEach((callback) => callback());
          failedRequestsQueue = [];

          return apiClient(originalRequest);
        } catch (err) {
          // Clear the queue and reject all pending requests
          failedRequestsQueue.forEach((callback) => callback());
          failedRequestsQueue = [];
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      } else {
        logOut();
        return Promise.reject(new Error('Unauthorized, please log in again.'));
      }
    }

    return Promise.reject(error);
  },
);

export const getToken = async (email: string, password: string): Promise<void> => {
  if (!email || !password) {
    throw new Error('Both email and password are required.');
  }

  try {
    const result = await axios.post<TokenResponse>(`${L2_API_BASE_URL}/token/pair`, {
      email,
      password,
    });

    localStorage.setItem('access_token', result.data.access);
    localStorage.setItem('refresh_token', result.data.refresh);
  } catch (err: unknown) {
    logOut();

    if (err instanceof AxiosError) {
      if (err.response) {
        console.log('Error response:', err.response);

        if (err.response.status === 400) {
          throw new Error('Both email and password are required.');
        } else if (err.response.status === 401) {
          throw new Error('Invalid credentials. Please try again.');
        } else {
          throw new Error(`Server error: ${err.response.status}`);
        }
      } else if (err.request) {
        throw new Error(
          'No response received from the server. Please check your network connection.',
        );
      }
    } else if (err instanceof Error) {
      throw new Error(err.message);
    } else {
      throw new Error('An unexpected error occurred while trying to log in.');
    }
  }
};

export function isLoggedIn(): boolean {
  return getStoredToken() !== null;
}

export function getStoredToken(): string | null {
  return localStorage.getItem('access_token');
}

export function getStoredRefreshToken(): string | null {
  return localStorage.getItem('refresh_token');
}

export function logOut(): void {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}

interface getUserListResponse {
  users: User[];
}

export async function getUserList(page: number): Promise<User[]> {
  try {
    const response = await apiClient.get<getUserListResponse>('/internal/users', {
      params: { page },
    });

    const { users } = response.data;

    return users;
  } catch (error) {
    console.error('Error fetching user list', error);
    throw error;
  }
}

export interface getUserDetailResponse {
  user: User;
  feeds: Feed[];
  completed_tasks: CompletedTask[];
  mind_events: MindEvent[];
  skills: DebugWordKnowledge[];
}

export interface getUserDetailProps {
  id: string;
  feeds?: boolean;
  completed_tasks?: boolean;
  mind_events?: boolean;
  skills?: boolean;
}

export async function getUserDetail({
  id,
  feeds = false,
  completed_tasks = false,
  mind_events = false,
  skills = false,
}: getUserDetailProps): Promise<getUserDetailResponse> {
  const params = new URLSearchParams();
  if (feeds) params.append('feeds', '1');
  if (completed_tasks) params.append('completed_tasks', '1');
  if (mind_events) params.append('mind_events', '1');
  if (skills) params.append('skills', '1');

  let result;
  try {
    result = await apiClient.get(`/internal/users/${id}`, { params });
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }

  if (result.status === 200 && result.data) {
    console.log('User detail:', result.data);
    return result.data;
  } else {
    throw new Error('Unexpected response from the server');
  }
}

export interface getFeedDetailResponse {
  feed: Feed;
  items: FlashCardSession[] | AudioDJSession[];
}

export async function getFeedDetail(id: string): Promise<getFeedDetailResponse> {
  let response;
  try {
    response = await apiClient.get(`/internal/feeds/${id}`);
    console.log(response.data);
  } catch (error) {
    console.error('Error fetching feed detail:', error);
    throw error;
  }

  if (response.data.length > 0) {
    const getDetailResponse: getFeedDetailResponse = {
      feed: response.data[0].feed,
      items: response.data,
    };
    console.log('Feed type:', getDetailResponse.feed.feed_type);
    console.log('Items:', getDetailResponse.items);
    return getDetailResponse;
  } else {
    throw new Error('No feed data found');
  }
}
