import { ReactNode } from 'react';
import { AppShell, Burger, Group, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import GliteLogo from '../assets/glite-logo.png';
import Navbar from '../pages/Navbar.tsx';

interface LayoutProps {
  title: string;
  children: ReactNode;
}

export function Layout({ title, children }: LayoutProps) {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Group w="284px">
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
            <img src={GliteLogo} width="35px" height="auto" alt="Glite logo icon" />
            <Title order={1} size="h2">
              Glite Admin
            </Title>
          </Group>

          <Group align="center">
            <Title order={2} size="h2">
              {title}
            </Title>
          </Group>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar p="md" w="300px">
        <Navbar />
      </AppShell.Navbar>

      <AppShell.Main m="md" mt="md">
        {children}
      </AppShell.Main>
    </AppShell>
  );
}
