import { useState, useEffect } from 'react';
import { Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import {
  ArrowRightStartOnRectangleIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';
import classes from './Navbar.module.css';
import ToggleTheme from '../components/ToggleTheme.tsx';

const data = [
  { link: '/', label: 'Home', icon: HomeIcon },
  { link: '/users', label: 'Users', icon: UsersIcon },
];

export default function Navbar() {
  const navigate = useNavigate();
  const currentUrl = window.location.pathname;
  const [active, setActive] = useState(
    data.find((item) => item.link === currentUrl)?.label ?? '',
  );

  useEffect(() => {
    const handleUrlChange = () => {
      const newActive =
        data.find((item) => item.link === window.location.pathname)?.label ?? '';
      setActive(newActive);
    };

    window.addEventListener('popstate', handleUrlChange);
    return () => window.removeEventListener('popstate', handleUrlChange);
  }, []);

  const links = data.map((item) => (
    <a
      className={classes.link}
      data-active={item.label === active ? true : undefined}
      href={item.link}
      key={item.label}
      onClick={() => {
        navigate(item.link);
      }}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </a>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>{links}</div>

      <div className={classes.footer}>
        <Group justify="space-between" flex="1">
          <a href="/logout" className={classes.link}>
            <ArrowRightStartOnRectangleIcon className={classes.linkIcon} />
            <span>Sign out</span>
          </a>
          <ToggleTheme />
        </Group>
      </div>
    </nav>
  );
}
