import { Layout } from '../components/Layout.tsx';
import { Title, Text } from '@mantine/core';

export default function Home() {
  return (
    <Layout title="User List">
      <Title size="h3">Home</Title>
      <Text>There is nothing here.</Text>
    </Layout>
  );
}
