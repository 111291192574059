import { createTheme, MantineProvider } from '@mantine/core';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { getStoredToken } from './services/api.ts';
import LoginPage from './pages/LoginPage';
import { useEffect } from 'react';
import FeedDetail from './pages/FeedDetail';
import UserDetail from './pages/UserDetail';
import '@mantine/core/styles.css';
import LogoutPage from './pages/LogoutPage.tsx';
import './App.css';
import UserList from './pages/UserList.tsx';
import Home from './pages/Home.tsx';

const theme = createTheme({
  fontFamily: 'Montserrat, sans-serif',
  defaultRadius: 'md',
});

function AuthWrapper({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = getStoredToken();
    if (!token && !location.pathname.match(/^\/login/)) {
      navigate('/login');
    }
  }, [location, navigate]);

  return <>{children}</>;
}
function App() {
  return (
    <MantineProvider theme={theme}>
      <Router>
        <AuthWrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/users/:id" element={<UserDetail />} />
            <Route path="/feed/:id" element={<FeedDetail />} />
          </Routes>
        </AuthWrapper>
      </Router>
    </MantineProvider>
  );
}

export default App;
