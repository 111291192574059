export enum PlatformsEnum {
  ANDROID = 'android',
  IOS = 'iOs',
  WEB = 'web',
}

export enum FeedTypeEnum {
  VISUAL = 'visual_feed',
  AUDIO = 'audio_feed',
}

export enum LanguageEnum {
  ENGLISH = 'en',
}

export interface ClientInfo {
  client_id: string;
  client_platform: PlatformsEnum;
  client_version: string;
}

export interface Feed {
  feed_id: string;
  user_id: string;
  client: ClientInfo;
  feed_type: FeedTypeEnum;
  language: LanguageEnum;
  last_updated_at: Date;
  items_count: number;
}

export interface User {
  user_id: string;
  firebase_id: string;
  email: string | null;
  email_verified: boolean;
  name: string | null;
  location: string | null;
  year_of_birth: number | null;
  gender: string | null;
  native_language: string | null;
  country_of_residence: string | null;
  education_level: string | null;
  industry: string | null;
  last_active_at: Date;
  last_updated_at: Date;
  created_at: Date;
  schema_version: number;
}
