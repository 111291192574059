import { SimpleGrid, Paper, Group, Title, Badge, Text, Button } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  DevicePhoneMobileIcon,
  ComputerDesktopIcon,
  ClockIcon,
} from '@heroicons/react/24/solid';
import { Feed, FeedTypeEnum, PlatformsEnum } from '../types/User';

interface UserFeedsProps {
  feeds: Feed[] | null;
}

export default function UserFeeds({ feeds }: UserFeedsProps) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (!feeds || feeds.length === 0) {
    return null;
  }

  return (
    <>
      <Title order={3} mt="xl" mb="lg">
        Feeds
      </Title>

      <SimpleGrid cols={isMobile ? 1 : 4} spacing="md">
        {feeds.map((feed) => (
          <Paper key={feed.feed_id} shadow="sm" p="md" radius="md" withBorder>
            <Group mb="xs">
              <Title
                order={4}
                style={{
                  color: feed.feed_type === FeedTypeEnum.VISUAL ? 'blue' : 'green',
                }}
                variant="light"
                size="lg"
              >
                {feed.feed_type === FeedTypeEnum.VISUAL ? 'Visual' : 'Audio'} Feed
              </Title>
              <Badge
                size="sm"
                style={{ marginLeft: 'auto' }}
                color={feed.feed_type === FeedTypeEnum.VISUAL ? 'blue' : 'green'}
              >
                {feed.language}
              </Badge>
            </Group>

            <Group align="center" justify="space-between" mt="md" mb="md">
              <Group gap="xs">
                {feed.client.client_platform === PlatformsEnum.IOS ||
                feed.client.client_platform === PlatformsEnum.ANDROID ? (
                  <DevicePhoneMobileIcon width={20} height={20} />
                ) : (
                  <ComputerDesktopIcon width={20} height={20} />
                )}
                <Text size="sm" fw={500}>
                  {feed.client.client_platform} v{feed.client.client_version}
                </Text>
              </Group>
              <Group align="center" gap="xs">
                <ClockIcon width={20} height={20} />
                <Text size="sm">
                  {new Date(feed.last_updated_at).toLocaleDateString()}
                </Text>
              </Group>
            </Group>

            <Button
              variant="light"
              size="xs"
              w="100%"
              component="a"
              href={`/feed/${encodeURI(feed.feed_id)}`}
            >
              View {feed.items_count} items
            </Button>
          </Paper>
        ))}
      </SimpleGrid>
    </>
  );
}
